<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
  >
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        class="form-inline"
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search Library"
          type="text"
        />
        <b-button
          v-b-modal.knowledgeBaseModal
          variant="primary"
        >
          <feather-icon icon="PlusIcon" />
          Add Library
        </b-button>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <span
          v-if="props.column.field === 'file'"
          class="d-flex flex-nowrap"
        >
          <Promised
            v-if="props.row.is_internal === true"
            :promise="getLink(props.row.file)"
          >
            <template v-slot:pending>
              <b-button
                class="mr-50"
                disabled
                size="sm"
                variant="outline-primary"
              ><feather-icon icon="ClockIcon" /></b-button>
            </template>
            <template v-slot="data">
              <b-button
                v-b-tooltip.hover.bottom="getFileType(data)"
                class="mr-50"
                size="sm"
                target="_blank"
                variant="outline-primary"
                @click="handleViewClick(data);"
              >View</b-button>
            </template>
            <template v-slot:rejected>
              <span v-b-tooltip="`File not found`">
                <b-button
                  class="mr-50"
                  disabled
                  size="sm"
                  variant="outline-danger"
                ><feather-icon icon="AlertTriangleIcon" /></b-button>
              </span>
            </template>
          </Promised>

          <b-button
            v-else
            v-b-tooltip.hover.bottom="getFileType(props.row.file)"
            :href="prependHttps(props.row.file)"
            size="sm"
            target="_blank"
            variant="outline-primary"
            class="mr-50"
          >View</b-button>
          <b-button
            size="sm"
            variant="outline-danger"
            @click="deleteFile(props.row.id)"
          >Delete</b-button>
        </span>
        <!-- Column: Common -->
        <span
          v-else-if="props.column.field === 'recommendation'"
          class="d-flex align-items-center justify-content-between"
        >
          {{ props.row.description }}  <b-badge
            variant="light-primary"
          >
            {{ props.formattedRow[props.column.field] }}
          </b-badge>
        </span>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template
        v-slot:column-filter="{ column, updateFilters }"
      >
        <span v-if="column.label === 'Operation'">
          <treeselect
            placeholder="Select Phase or Activities"
            :normalizer="filterNormalizer"
            :append-to-body="true"
            :options="operations"
            style="border-collapse: separate; max-width: 240px;"
            class="form-control p-0 border-0"
            @input="(val) => updateFilters(column, val)"
          />
        </span>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','30']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
      id="knowledgeBaseModal"
      ok-only
      no-close-on-backdrop
      ok-title="Upload"
      size="lg"
      title="Add Knowledge Base"
      @ok="addFile"
    >
      <b-overlay :show="$apollo.loading || mutationLoading">
        <validation-observer
          ref="uploadForm"
          tag="form"
        >
          <b-row>
            <b-col cols="6">

              <b-form-group
                label="Title"
                label-size="sm"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    v-model="title"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Title"
                    required
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Description"
                label-size="sm"
              >
                <b-form-input
                  v-model="description"
                  placeholder="File Description"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <validation-provider
                #default="{ errors }"
                name="Operation"
                rules="required"
              >
                <b-form-group
                  label="Operation"
                  label-size="sm"
                >
                  <treeselect
                    v-model="selectedOperation"
                    :class=" errors.length > 0 ? 'border-danger rounded' : ''"
                    :normalizer="normalizer"
                    :options="operations"
                    placeholder="Select Phase, Subphase or Activities"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                #default="{ errors }"
                name="Recommendation"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                  label="Recommendation Level"
                  label-size="sm"
                >
                  <v-select
                    v-model="recommendationLevel"
                    :options="['Priority', 'Interesting', 'Additional', 'Good-Read']"
                    placeholder="Recommendation Level"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <!-- primary -->
              <div class="d-flex align-items-center">
                <span>Upload File?</span>
                <b-form-checkbox
                  v-model="is_internal"
                  class="ml-50"
                  inline
                  switch
                ><span class="switch-icon-left">
                   <feather-icon icon="UploadIcon" />
                 </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="LinkIcon" />
                  </span></b-form-checkbox>
              </div>
              <hr>
            </b-col>
            <b-col
              v-if="is_internal"
              cols="12"
            >
              <h6>Upload File</h6>
              <!-- Styled -->
              <validation-provider
                #default="{ errors }"
                name="File"
                rules="size:10000"
              >
                <b-form-file
                  v-model="file"
                  :state="errors.length > 0 ? false:null"
                  drop-placeholder="Drop file here..."
                  placeholder="Choose a file or drop it here..."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col
              v-else
              cols="12"
            >
              <h6>External Link</h6>
              <b-form-input
                v-model="file_link"
                placeholder="https://"
              />
            </b-col>
          </b-row>
        </validation-observer>
      </b-overlay>
    </b-modal>
    <b-modal id="fileViewer">
      <div v-if="getFileType(fileUrl) && getFileType(fileUrl) === 'PDF'">
        <pdf
          :src="fileUrl"
          @num-pages="pageCount = $event"
          @page-loaded="currentPage = $event"
        />
      </div>
      <div
        v-else-if="getFileType(fileUrl) === 'Photo'"
      >
        <img
          :src="fileUrl"
          alt="Preview Image"
          style="max-width: 100%;"
        >
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-danger "
          @click="cancel"
        >
          Close
        </b-button>
        <b-button
          :href="fileUrl"
          target="_blank"
          variant="primary"
          @click="$bvModal.hide('fileViewer');"
        >
          Download
        </b-button>
      </template>
    </b-modal>
  </b-overlay>
</template>
<script>

import {
  BBadge,
  BButton,
  BCol,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Promised } from 'vue-promised'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, size } from '@core/utils/validations/validations'
import pdf from 'vue-pdf'

export default {
  components: {
    pdf,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    vSelect,
    Treeselect,
    BBadge,
    BFormCheckbox,
    BFormFile,
    Promised,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      mutationLoading: false,
      searchTerm: '',
      selectedRow: 0,
      pageLength: 10,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Title',
          field: 'document_name',
          width: '25%',
        },
        {
          label: 'Description',
          field: 'recommendation',
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
            placeholder: '-- No Filter --', // placeholder for search input
            filterDropdownItems: ['Priority', 'Interesting', 'Additional Reading', 'Good-Read'],
          },
        },
        {
          label: 'Operation',
          field(row) {
            return row.programs_operationstable.title
          },
          filterOptions: {
            enabled: true, // enable filter for this column
            // Custom filter function
            filterFn(data, filterString) {
              if (!filterString || !Number.parseInt(filterString, 10)) return true
              return data.id === Number.parseInt(filterString, 10)
            },
          },
        },
        {
          label: 'File',
          field: 'file',
          sortable: false,
        },
      ],
      rows: [],
      operations: [],
      is_internal: true,
      file_link: null,
      fileUrl: null,
      file: null,
      recommendationLevel: null,
      title: null,
      description: '',
      selectedOperation: null,
      required,
      size,
    }
  },
  methods: {
    handleViewClick(url) {
      this.fileUrl = url
      this.downloadFileName = url.substring(url.lastIndexOf('/') + 1)
      if (this.getFileType(url) && this.getFileType(url) !== 'PDF' && this.getFileType(url) !== 'Photo') {
        window.open(url, '_blank')
      } else {
        this.$bvModal.show('fileViewer')
      }
    },
    getFileType(fileName) {
      if (!fileName) return 'Unknown'
      const extension = fileName.split('.')
        .pop()
        .toLowerCase()
        .substring(0, 3)
      const fileTypes = {
        doc: 'Document',
        docx: 'Document',
        ppt: 'PPT',
        pptx: 'PPT',
        pdf: 'PDF',
        mp4: 'Video',
        avi: 'Video',
        mkv: 'Video',
        jpg: 'Photo',
        jpe: 'Photo',
        png: 'Photo',
        gif: 'Photo',
        xls: 'Excel Sheet',
        xlsx: 'Excel Sheet',
        link: 'Link',
        com: 'Link',
        // Add more file types as needed
      }
      const fileType = fileTypes[extension] || 'Unknown'
      return fileType
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    filterNormalizer(node) {
      return {
        id: node.title,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    getLink(key) {
      return fetch(`https://4rf1lach53.execute-api.ap-south-1.amazonaws.com/dev/programs/files/?file_key=${key}`, {
        method: 'GET',
      }).then(response => response.json())
        .then(data => data.url)
    },
    async addFile(bvModalEvent) {
      bvModalEvent.preventDefault()
      const validation = await this.$refs.uploadForm.validate()
      if (!validation) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please fill all required fields',
            icon: 'ErrorIcon',
            variant: 'danger',
          },
        })
        return false
      }
      if (!this.file && !this.file_link) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please upload a file or provide a link',
            icon: 'ErrorIcon',
            variant: 'danger',
          },
        })
        return false
      }
      this.mutationLoading = true
      let fileLink = this.file_link
      if (this.is_internal) {
        const formData = new FormData()
        formData.append('file', this.file)
        await fetch('https://4rf1lach53.execute-api.ap-south-1.amazonaws.com/dev/programs/files/upload/', {
          method: 'POST',
          body: formData,
        }).then(response => response.json())
          .then(data => {
            fileLink = data.file_key
            return data
          })
      }
      await this.$apollo.mutate(
        {
          mutation: gql`mutation MyMutation {
                      insert_programs_knowledgeresourcetable_one(object: {document_name: "${this.title}",recommendation: "${this.recommendationLevel}", description: "${this.description}", file: "${fileLink}", is_internal: "${this.is_internal}", operations_id: ${this.selectedOperation}}) {
                        id
                      }
                    }`,
          update: () => {
            this.mutationLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Resource added successfully',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$bvModal.hide('knowledgeBaseModal')
            this.$apollo.queries.rows.refetch()
          },
        },
      ).catch(() => {
        this.mutationLoading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'ErrorIcon',
            variant: 'danger',
          },
        })
      })
      this.title = null
      this.file_link = null
      this.description = null
      this.selectedOperation = null
      this.recommendationLevel = null
      this.selectedOperation = null
      return true
    },
    deleteFile(id) {
      // msgOkcofirm
      this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete this file?',
        {
          okTitle: 'No, Thanks',
          cancelTitle: 'Yes, Delete',
          title: 'Delete File',
        },
      )
        .then(value => {
          if (value === false) {
            this.mutationLoading = true
            this.$apollo.mutate(
              {
                mutation: gql`mutation MyMutation {
                      update_programs_knowledgeresourcetable_by_pk(pk_columns: {id: ${id}}, _set: {is_deleted: true}) {
                        id
                      }
                    }`,
                update: () => {
                  this.mutationLoading = false
                  this.$apollo.queries.rows.refetch()
                },
              },
            )
          }
        })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_knowledgeresourcetable(order_by: { id: desc}, where: {programs_operationstable: {program_id: {_eq:  ${this.$route.params.id}}}}) {
            id
            description
            document_name
            file
            recommendation
            is_internal
            programs_operationstable {
              title
            }
          }
        }`
      },
      update: data => data.programs_knowledgeresourcetable,
    },
    operations: {
      query() {
        return gql`{
            programs_operationstable(order_by: { id: asc}, where: {program_id: {_eq: ${this.$route.params.id}}, operations_type: {_eq: "SubPhase"}}) {
                  id
                  title
                  programs_operationstables(order_by: { id: asc},where: {operations_type: {_eq: "SubPhase"}}) {
                    id
                    title
                    programs_operationstables(order_by: { id: asc}) {
                      id
                      title
                    }
                  }
                }
              }`
      },
      update: data => data.programs_operationstable,
    },
  },
}
</script>
